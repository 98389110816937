import { Route, Switch } from "react-router";
import GetMobileAppPage from "./pages/GetMobileAppPage";
import HomePage from "./pages/HomePage";
import LearnMoreMobile from "./pages/LearnMoreMobile";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/learnmore" component={LearnMoreMobile} />
      <Route exact path="/getapp" component={GetMobileAppPage} />
    </Switch>
  );
}

export default Routes;
