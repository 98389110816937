import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

import logo from "../static/images/logos/mainLogo.svg";
import employmentGroup from "../static/images/logos/employmentGroup.svg";
import employersIcn from "../static/images/clipIcons/employers.svg";
import earner from "../static/images/clipIcons/earners.svg";
import lendingCompanies from "../static/images/clipIcons/lendingCompanies.svg";
import accountants from "../static/images/clipIcons/accountants.svg";
import bgBusiness from "../static/images/bgs/bgBusiness.jpg";
import bgPolitics from "../static/images/bgs/bgPolitics.jpg";
import bgSports from "../static/images/bgs/bgSports.jpg";
import bgTaxes from "../static/images/bgs/bgTaxes.jpg";
import useWindowDimensions from "../utils/WindowSize";
import { useHistory } from "react-router";
import ModalComingSoon from "../components/ModalComingSoon";

const list = [
  {
    key: 1,
    bg: bgTaxes,
    title: "File Your Taxes",
    desc: "In Addition To Helping You Get Paid Instantly, Snapay’s Inbuilt Accounting System Monitors, Prepares, And Submits All Your Tax Filings Without Wasting Any Of Your Precious Time.",
    btText: "For Accountants",
    btIcon: accountants,
  },
  {
    key: 2,
    bg: bgBusiness,
    title: "Track Your Earnings",
    desc: "In Today’s Dynamic World You Can Make Money In Multiple Ways. Snapay Is Here To Make Sure That All Your Income Generating Activities Are Tracked, Organized, And Presentable.",
    btText: "For Employers",
    btIcon: employersIcn,
  },
  {
    key: 3,
    bg: bgSports,
    title: "Get A Salary Advance",
    desc: "Waiting For Your Next Paycheck Is Inhumane. That Is Why We Enable You To Access Money From Your Next Paycheck As Soon As You Complete The Work.",
    btText: "For Lending Companies",
    btIcon: lendingCompanies,
  },
  {
    key: 4,
    bg: bgPolitics,
    title: "Cash Your Bonuses",
    desc: "Take Advantage Of All The Incentives Your Employer Has To Offer In One Simple Interface. Analyze Your Performance Against The Incentives Which You Could Qualify For.",
    btText: "For Employers",
    btIcon: employersIcn,
  },
];

function LearnMoreMobile() {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(1);

  const isPrev = (i) => {
    if (index === 0) {
      if (i === list.length - 1) return true;
    } else {
      if (i === index - 1) return true;
    }
    return false;
  };
  const isNext = (i) => {
    if (index === list.length - 1) {
      if (i === 0) return true;
    } else {
      if (i === index + 1) return true;
    }
    return false;
  };
  const handlers = useSwipeable({
    onSwipedRight: () => setIndex(index === 0 ? list.length - 1 : index - 1),
    onSwipedLeft: () => setIndex(index === list.length - 1 ? 0 : index + 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const [soonModal, setSoonModal] = useState(false);

  if (width > 768) {
    history.push("/");
  }
  return (
    <div className="learnMoreMobile" {...handlers}>
      <div className="navbar">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="bgWrap">
        <img src={bgTaxes} alt="" className="bgHid" />
        <div className="carouselWrap">
          {list.map((item, i) => (
            <img
              onClick={() => setIndex(i)}
              src={item.bg}
              key={item.key}
              alt=""
              className={`content ${
                index === i
                  ? "active"
                  : isPrev(i)
                  ? "prev"
                  : isNext(i)
                  ? "next"
                  : "inactive"
              }`}
            />
          ))}
        </div>
      </div>
      <div className="titleOne">Snap Your Fingers And</div>
      <div className="titleTwo">{list[index]?.title}</div>
      <p className="desc">{list[index]?.desc}</p>
      <div className="btns">
        <div
          className="btnIdea"
          onClick={() => {
            setSoonModal(true);
          }}
        >
          <img src={earner} alt="" /> For Earners
        </div>
        <div
          className="btnTalk"
          onClick={() => {
            setSoonModal(true);
          }}
        >
          <img src={[list[index].btIcon]} alt="" /> {list[index].btText}
        </div>
      </div>
      {soonModal && <ModalComingSoon onClose={() => setSoonModal(false)} />}
    </div>
  );
}

export default LearnMoreMobile;
