import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import android from "../static/images/clipIcons/androidColor.svg";
import ios from "../static/images/clipIcons/iosColor.svg";
import useWindowDimensions from "../utils/WindowSize";

function GetMobileAppPage() {
  const { height } = useWindowDimensions();
  const [step, setStep] = useState(0);
  const [platform, setPlatform] = useState("");
  const [resLink, setResLink] = useState("");
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    Axios.get(
      "https://comms.globalxchange.com/gxb/apps/mobile/app/links/logs/get?app_code=ice"
    ).then(({ data }) => {
      setResLink(data.logs[0]);
    });
  }, []);

  function getContent() {
    switch (step) {
      case 0:
        return (
          <div className="getAppContent">
            <div class="mainTitle">Select Device</div>
            <div
              className="btnItem"
              onClick={() => {
                setStep(1);
                setPlatform("android");
              }}
            >
              <img src={android} alt="" />
              <span>Android</span>
            </div>
            <div
              className="btnItem"
              onClick={() => {
                setStep(1);
                setPlatform("ios");
              }}
            >
              <img src={ios} alt="" />
              <span>iPhone</span>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="getAppContent">
            <div class="mainTitle">Next Step</div>
            <div
              className="btnItem"
              onClick={() => {
                setStep(2);
              }}
            >
              <span>Download App On This Device</span>
            </div>
            <div
              className="btnItem disable"
              onClick={() => {
                setStep(2);
              }}
            >
              <span>Send Me The Download Link</span>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="getAppContent">
            <div class="mainTitle">Download App On This Phone</div>
            <div
              className="btnItem"
              onClick={() => {
                setStep(3);
              }}
            >
              <span>Copy Download Link</span>
            </div>
            <a
              href={resLink && resLink[`${platform}_app_link`]}
              target="_blank"
              rel="noopener noreferrer"
              className="btnItem"
            >
              <span>Open Download Link</span>
            </a>
          </div>
        );
      case 3:
        return (
          <div className="getAppContent">
            <div class="mainTitle">Download Link</div>
            <div
              className="btnItem"
              onClick={() => {
                navigator.clipboard
                  .writeText(resLink && resLink[`${platform}_app_link`])
                  .then(() => {
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 2000);
                  });
              }}
            >
              <span>
                {copied
                  ? "Copied To Clipboard"
                  : resLink && resLink[`${platform}_app_link`]}
              </span>
              <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
        );

      default:
        return;
    }
  }
  return (
    <div className="getMobileApp" style={{ height }}>
      {getContent()}
      <Link to="/" className="goHome">
        Back Home
      </Link>
    </div>
  );
}

export default GetMobileAppPage;
