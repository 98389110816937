import React from "react";
import { v4 as uuidv4 } from "uuid";
import { CSSTransition, TransitionGroup } from "react-transition-group";

function HomeCarouselComponent({ list, setList }) {
  return (
    <div className="homeCarousel">
      <TransitionGroup className="list">
        {list?.map((bg, i) => (
          <CSSTransition key={bg.key} timeout={300} classNames="item">
            <img
              className="item"
              src={bg.bg}
              key={bg.key}
              alt=""
              onClick={() => {
                if (i === 0) {
                  setList((list) => {
                    const [...first] = list;
                    const last = first.pop();
                    return [{ ...last, key: uuidv4() }, ...first];
                  });
                }
                if (i === 2) {
                  setList((list) => {
                    const [first, ...rest] = list;
                    return [...rest, { ...first, key: uuidv4() }];
                  });
                }
              }}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  );
}

export default HomeCarouselComponent;
