import React from "react";

import logo from "../../static/images/logos/mainLogo.svg";
import android from "../../static/images/clipIcons/androidColor.svg";
import ios from "../../static/images/clipIcons/iosColor.svg";

function SendInviteModalPlatform({ onClose, setPlatform }) {
  return (
    <div className="inviteModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (e) {}
        }}
      />
      <div className="modalInvite">
        <>
          <div className="headInvite">
            <img src={logo} alt="" className="headLogo" />
          </div>
          <div className="contentInvite platform">
            <label htmlFor="">Which App Do You Want? </label>
            <div className="inputBoxIn">
              <div className="boxApp" onClick={() => setPlatform("android")}>
                <img src={android} alt="" />
                Android
              </div>
              <div className="boxApp" onClick={() => setPlatform("ios")}>
                <img src={ios} alt="" />
                Iphone
              </div>
            </div>
          </div>
        </>
        {/* ) : (
          <>
            <div className="headInviteMain">
              <img src={logo} alt="" className="headLogo" />
            </div>
            <div className="contentInvite main">
              <p className="downloadDesc">
                To Spend Crypto From Your {appName} Wallet You Need To Login To
                The SpendCrypto App With Your {appName} Credentials.{" "}
              </p>
            </div>
            <div className="footer" onClick={() => setDownload(true)}>
              Download The App
            </div>
          </>
        )} */}
      </div>
    </div>
  );
}

export default SendInviteModalPlatform;
