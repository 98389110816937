import React from "react";

import comingSoon from "../../static/images/comingSoon.svg";

function ModalComingSoon({ onClose }) {
  return (
    <div className="modalSoon">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <img src={comingSoon} alt="" className="comingSoon" />
    </div>
  );
}

export default ModalComingSoon;
