import React from "react";
import useWindowDimensions from "../../utils/WindowSize";
import style from "./mainLayout.module.scss";

function MainLayout({ children, chatOn, chatComponent, className }) {
  const { height } = useWindowDimensions();
  return (
    <div className={`${className} ${style.mainLayout}`} style={{ height }}>
      <div className={`mainArea ${style.mainArea} ${chatOn}`}>{children}</div>
      {chatOn && chatComponent}
    </div>
  );
}

export default MainLayout;
