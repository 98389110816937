import React, { useState } from "react";
import MainLayout from "../layouts/ChatLayout/MainLayout";
import logoIcon from "../static/images/logos/mainIcon.svg";
import discussionText from "../static/images/logos/discussionText.svg";
import merchants from "../static/images/clipIcons/merchants.svg";
import earner from "../static/images/clipIcons/earners.svg";
import mobileIcon from "../static/images/clipIcons/mobileIcon.svg";
import { Link, useHistory } from "react-router-dom";
import ModalComingSoon from "../components/ModalComingSoon";

function HomeMobile() {
  const history = useHistory();
  const [soonModal, setSoonModal] = useState(false);
  return (
    <MainLayout className="homeMobile">
      <div className="head">
        <img src={logoIcon} alt="" />
        <img className="title" alt="" src={discussionText} />
      </div>
      <div className="btns">
        <div
          className="btnIdea"
          onClick={() => {
            // window.open("https://my.snapay.com/", "_blank").focus();
            setSoonModal(true);
          }}
        >
          <img src={earner} alt="" /> For Earners
        </div>
        <div
          className="btnTalk"
          onClick={() => {
            history.push("/learnmore");
          }}
        >
          <img src={merchants} alt="" /> Learn More
        </div>
      </div>
      <Link
        to="/getapp"
        onClick={(e) => {
          e.preventDefault();
          setSoonModal(true);
        }}
        className="footer"
      >
        <img src={mobileIcon} alt="" />
        Download Mobile App
      </Link>
      {soonModal && <ModalComingSoon onClose={() => setSoonModal(false)} />}
    </MainLayout>
  );
}

export default HomeMobile;
