import React, { Fragment, useContext, useState } from "react";
import HomeCarouselComponent from "../components/HomeCarouselComponent/HomeCarouselComponent";
import { ChatsContext } from "../context/ChatsContext";
import MainLayout from "../layouts/ChatLayout/MainLayout";
import MainNavbar from "../layouts/MainNavbar/MainNavbar";

import logo from "../static/images/logos/mainLogo.svg";
import employmentGroup from "../static/images/logos/employmentGroup.svg";
import employersIcn from "../static/images/clipIcons/employers.svg";
import earner from "../static/images/clipIcons/earners.svg";
import lendingCompanies from "../static/images/clipIcons/lendingCompanies.svg";
import accountants from "../static/images/clipIcons/accountants.svg";
import bgBusiness from "../static/images/bgs/bgBusiness.jpg";
import bgPolitics from "../static/images/bgs/bgPolitics.jpg";
import bgSports from "../static/images/bgs/bgSports.jpg";
import bgTaxes from "../static/images/bgs/bgTaxes.jpg";
import useWindowDimensions from "../utils/WindowSize";
import HomeMobile from "./HomeMobilePage";
import ModalComingSoon from "../components/ModalComingSoon";

function HomePage() {
  const { chatOn, setChatOn } = useContext(ChatsContext);
  const { width } = useWindowDimensions();

  const [list, setList] = useState([
    {
      key: 1,
      bg: bgTaxes,
      title: "File Your Taxes",
      desc: "In Addition To Helping You Get Paid Instantly, Snapay’s Inbuilt Accounting System Monitors, Prepares, And Submits All Your Tax Filings Without Wasting Any Of Your Precious Time.",
      btText: "For Accountants",
      btIcon: accountants,
    },
    {
      key: 2,
      bg: bgBusiness,
      title: "Track Your Earnings",
      desc: "In Today’s Dynamic World You Can Make Money In Multiple Ways. Snapay Is Here To Make Sure That All Your Income Generating Activities Are Tracked, Organized, And Presentable.",
      btText: "For Employers",
      btIcon: employersIcn,
    },
    {
      key: 3,
      bg: bgSports,
      title: "Get A Salary Advance",
      desc: "Waiting For Your Next Paycheck Is Inhumane. That Is Why We Enable You To Access Money From Your Next Paycheck As Soon As You Complete The Work.",
      btText: "For Lending Companies",
      btIcon: lendingCompanies,
    },
    {
      key: 4,
      bg: bgPolitics,
      title: "Cash Your Bonuses",
      desc: "Take Advantage Of All The Incentives Your Employer Has To Offer In One Simple Interface. Analyze Your Performance Against The Incentives Which You Could Qualify For.",
      btText: "For Employers",
      btIcon: employersIcn,
    },
  ]);

  const [soonModal, setSoonModal] = useState(false);

  return (
    <Fragment>
      {width > 768 ? (
        <MainLayout className="homePage">
          <MainNavbar
            className=""
            logo={logo}
            onLogoClick={() => {}}
            chatOn={chatOn}
            setChatOn={setChatOn}
            btIcon={employmentGroup}
            onBtClick={() => {
              window.open("http://employment.group/", "_blank");
            }}
            btLabel="Employment Group"
          />
          <div className="homeView">
            <div className="content">
              <div className="titleOne">Snap Your Fingers And</div>
              <div className="titleTwo">{list[1]?.title}</div>
              <p className="desc">{list[1]?.desc}</p>
              <div className="btns">
                <div
                  className="btnIdea"
                  onClick={() => {
                    setSoonModal(true);
                  }}
                >
                  <img src={earner} alt="" /> For Earners
                </div>
                <div
                  className="btnTalk"
                  onClick={() => {
                    setSoonModal(true);
                  }}
                >
                  <img src={[list[1].btIcon]} alt="" /> {list[1].btText}
                </div>
              </div>
            </div>
            <HomeCarouselComponent list={list} setList={setList} />
          </div>
          {soonModal && <ModalComingSoon onClose={() => setSoonModal(false)} />}
        </MainLayout>
      ) : (
        <HomeMobile />
      )}
    </Fragment>
  );
}

export default HomePage;
