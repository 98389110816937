import React, { useState } from "react";
import { ReactComponent as MobileIcon } from "../../static/images/clipIcons/mobileIcon.svg";
import styles from "./mainNavbar.module.scss";

import SendInviteModal from "../../components/SendInviteModal/SendInviteModal";
import SendInviteModalPlatform from "../../components/SendInviteModal/SendInviteModalPlatform";
import ModalComingSoon from "../../components/ModalComingSoon";

function MainNavbar({
  className,
  logo,
  onLogoClick,
  btIcon,
  onBtClick,
  btLabel,
}) {
  const [platform, setPlatform] = useState("");
  const [inviteModal, setInviteModal] = useState(false);

  return (
    <>
      <nav className={`${styles.navCompact} ${className}`}>
        <div
          className={styles.navHome}
          onClick={() => {
            try {
              onBtClick();
            } catch (error) {}
          }}
        >
          <div className={styles.logo}>
            <img src={btIcon} alt="" />
            <div className={`${styles.label} ${styles.left}`}>{btLabel}</div>
          </div>
        </div>
        <div className={styles.area}>
          <img
            src={logo}
            alt=""
            className={styles.society}
            onClick={() => {
              try {
                onLogoClick();
              } catch (error) {}
            }}
          />
        </div>
        <div className={styles.navHome}>
          <div className={styles.logo} onClick={() => setInviteModal(true)}>
            <MobileIcon />
            <div className={`${styles.label} ${styles.right}`}>Mobile Apps</div>
          </div>
        </div>
      </nav>
      {/* Temp Remove */}
      {/* {inviteModal &&
        (platform ? (
          <SendInviteModal
            onClose={() => {
              setPlatform("");
              setInviteModal(false);
            }}
            platform={platform}
          />
        ) : (
          <SendInviteModalPlatform
            onClose={() => {
              setPlatform("");
              setInviteModal(false);
            }}
            setPlatform={setPlatform}
          />
        ))} */}
      {inviteModal && <ModalComingSoon onClose={() => setInviteModal(false)} />}
    </>
  );
}

export default MainNavbar;
